import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { apiurl } from './apiurlhelper';

@Injectable({
  providedIn: 'root'
})
export class BillingService {

  constructor(private http: HttpClient) { }
  sendOTP(devicePrice){
    let body={"email":devicePrice.email, 'otp_type' :devicePrice.otp_type};
    return this.http.post(apiurl+"/sendotp",JSON.stringify(body))
  }
  policy(plans){
    const policy_plan={
      plan:plans
    }
    return this.http.post(apiurl+"/getpolicy",JSON.stringify(policy_plan))
  }
}
