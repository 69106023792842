import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { apiurl } from '../services/apiurlhelper';
@Injectable({
  providedIn: 'root'
})
export class CheckoutService {

  constructor(private http: HttpClient) { }
  payment(token, value, currency, ref_id,customer) {
    let price_value: any;
    if (currency == 'BIF' || currency == 'DJF' || currency == 'GNF' || currency == 'ISK' || currency == 'KMF' || currency == 'XAF' ||
      currency == 'CLF' || currency == 'XPF' || currency == 'JPY' || currency == 'PYG' || currency == 'RWF' || currency == 'KRW' ||
      currency == 'VUV' || currency == 'VND' || currency == 'XOF') {
      price_value = value;
    } else if (currency == 'BHD' || currency == 'LYD' || currency == 'JOD' || currency == 'KWD' || currency == 'OMR' || currency == 'TND') {
      price_value = value * 1000;
    } else {
      price_value = value * 100;
    }


    let apiurl = "https://api.checkout.com";
    let body = {
      "source": {
        "type": "token",
        "token": token
      },
      "amount": price_value,
      "currency": currency,
      "reference": ref_id,
      "customer":customer
      

    };
    // sk_b608127b-a176-4878-b3ae-cac4d3775ec7 https://api.checkout.com "
    if(currency === 'AED'){
      const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'sk_dbb95d2e-9007-4b4d-9acf-e5b2d3b997f7' });
      return this.http.post(apiurl + "/payments", JSON.stringify(body), { headers: headers })
    }else{
      const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'sk_b608127b-a176-4878-b3ae-cac4d3775ec7' });
      return this.http.post(apiurl + "/payments", JSON.stringify(body), { headers: headers })
    }
   
   
  }
  // checkout(id){
  //   let body={};
  //   let apiurl = "https://api.sandbox.checkout.com/payments/";
  //   const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'sk_test_0e2663e6-6870-41e9-8649-4627bf882b10' });
  //   return this.http.post(apiurl + id+"/captures", JSON.stringify(body), { headers: headers })
  // }

  payment_details(res_val) {

    return this.http.post(apiurl + "/placeorder", JSON.stringify(res_val))
  }

}
